import { useBanner, useBannerStylesheet, yieldLazyLoad } from "../../..";
import { useRef, useEffect } from "react";
import { extendBannerContentStylesheet, extendCommonButtonsStylesheet, fastdom } from "@devowl-wp/web-cookie-banner";
const BannerHeader = yieldLazyLoad(import( /* webpackMode: "eager" */"../header/header").then(_ref => {
  let {
    BannerHeader
  } = _ref;
  return BannerHeader;
}));
const BannerBody = yieldLazyLoad(import( /* webpackMode: "eager" */"../body/body").then(_ref2 => {
  let {
    BannerBody
  } = _ref2;
  return BannerBody;
}));
const BannerFooter = yieldLazyLoad(import( /* webpackMode: "eager" */"../footer/footer").then(_ref3 => {
  let {
    BannerFooter
  } = _ref3;
  return BannerFooter;
}));
const BannerContent = () => {
  const {
    Content,
    hideOnMobileClass,
    dimsContent,
    dimsOverlay,
    dimsHeader,
    dimsFooter,
    dimsRightSidebar,
    A11ySkipToLink,
    a11yIds: {
      firstButton
    }
  } = useBannerStylesheet().extend(...extendCommonButtonsStylesheet).extend(...extendBannerContentStylesheet);
  const {
    decision: {
      acceptAll,
      acceptEssentials,
      showCloseIcon
    },
    mobile,
    individualPrivacyOpen,
    bodyDesign: {
      acceptEssentialsUseAcceptAll
    },
    activeAction,
    pageRequestUuid4,
    i18n: {
      skipToConsentChoices
    }
  } = useBanner();
  const ref = useRef();
  const useAcceptEssentials = acceptEssentialsUseAcceptAll && acceptAll === acceptEssentials ? acceptAll : acceptEssentials;
  const headerClassName = mobile.hideHeader && !activeAction && !individualPrivacyOpen && (useAcceptEssentials === "hide" ? !showCloseIcon : true) ? hideOnMobileClass : "";
  const headerRef = useRef();
  const footerRef = useRef();
  const rightSideContainerRef = useRef();
  useEffect(() => {
    const animationContainer = [document.querySelector(`#${pageRequestUuid4} div[class*="animate__"]`)];
    const disposeFns = [dimsContent[0](ref.current), dimsOverlay[0](document.querySelector(`#${pageRequestUuid4}`), animationContainer), dimsHeader[0](headerRef.current, animationContainer), dimsFooter[0](footerRef.current, animationContainer), dimsRightSidebar[0](rightSideContainerRef.current, animationContainer)];
    return () => disposeFns.forEach(c => c());
  }, [ref.current, headerRef.current, footerRef.current, rightSideContainerRef.current]);
  useEffect(() => {
    // Scroll to top automatically at first visit (e.g. `<dialog` auto focuses the first found element)
    fastdom.mutate(() => setTimeout(() => ref.current.scrollTop = 0, 0));
  }, [individualPrivacyOpen]);
  return h(Content, {
    ref: ref
  }, h(A11ySkipToLink, {
    href: `#${firstButton}`
  }, skipToConsentChoices), h(BannerHeader, {
    ref: headerRef,
    className: headerClassName
  }), h(BannerBody, {
    rightSideContainerRef: rightSideContainerRef
  }), h(BannerFooter, {
    ref: footerRef
  }));
};
export { BannerContent };